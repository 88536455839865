import React from 'react';
import Head from 'next/head';
import Favicon from '@/dataroom/ui/components/Landing/dealvdr/components/Favicon';
import ReadyToLearn from '@/dataroom/ui/components/Landing/dealvdr/components/ReadyToLearn';
import Intro from './Intro';
import Features from './Features';
import Customers from './Customers';
import Feedbacks from './Feedbacks';

const Home = () => (
  <>
    <Favicon />
    <Head>
      <title>Secure Virtual Data Room | DealVDR</title>
      <meta
        name="description"
        content={
          'Secure, fast, intuitive and cost effective virtual data room trusted by ' +
          'thousands of investment banks and corporations globally, backed by fanatical 24x7 support.'
        }
      />
    </Head>
    <Intro />
    <Customers />
    <Features />
    <Feedbacks />
    <ReadyToLearn />
  </>
);

export default Home;
