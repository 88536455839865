import React from 'react';
import cn from 'classnames';
import { TabLink as NextNavLink } from '@/Framework/Router/Next/Link';
import FeaturesComponent from '@/Framework/UI/Pages/Landing/Sections/Features';
import Section from '@/Framework/UI/Pages/Landing/Sections/Section';
import SectionContainer from '@/Framework/UI/Pages/Landing/Sections/SectionContainer';
import SectionHeader from '@/Framework/UI/Pages/Landing/Sections/SectionHeader';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import { featuresTabsList } from '../../Features/tabs';

import baseStyles from '@/dataroom/ui/components/Landing/dealvdr/tenantDealvdrStyles.scss';
import styles from './features.scss';

const Features = () => (
  <Section padding="top">
    <SectionContainer>
      <SectionHeader
        title="Execute with confidence on DealVDR"
        description="DealVDR is easier to set up and manage with all the functionality you need to get your files and users into and out of the data room as quickly and securely as possible."
        maxWidth={ 770 }
      />
    </SectionContainer>
    <SectionContainer size="large" narrow>
      <FeaturesComponent
        autoplay
        actionButton={ (
          <NextNavLink
            to="/features"
            data-test="exploreOurFeatures"
          >
            <Button
              variant={ variantTypes.action }
              className={ cn(baseStyles.actionButton, styles.exploreButton) }
              title="Explore & Compare Our Features"
              dataTest="exploreOurFeaturesButton"
            />
          </NextNavLink>
        ) }
        tabsList={ featuresTabsList }
      />
    </SectionContainer>
  </Section>
);

export default Features;
